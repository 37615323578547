@media (min-width: 960px) {
    .container-main {
        max-width: 90%;
    }
}

@media (min-width: 961px) {
    .container-main {
        max-width: 1200px;
    }
}

#root {
    background: var(--standard-bg);
    color: floralwhite;
    min-height: 100%;
    padding: 0 2rem;
}

:root {
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: rgba(188, 143, 143, 0.32);
    --bs-body-bg: #fff;
    --standard-bg: #2e3546;
    --standard-bg-op: rgba(46, 53, 70, 0.4);
    --standard-bg-cold: rgba(63, 85, 131, 0.37);
    --standard-bg-colder: rgba(97, 87, 107, 0.43);
    --fz-xl: 20px;
    --navy-shadow: rgba(3, 19, 42, 0.7);
}

#html {
    background: var(--standard-bg);
}

#body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    background: var(--standard-bg);
    color: var(--bs-body-color);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

.-foot { background-color: var(--standard-bg-cold);
}

.p-p {
    margin-top: 40px;
    max-width: 485px;
    min-width: 300px;
    font-size: var(--bs-body-font-size);
}

.kit {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
}

.kit-bg-blr {
    -webkit-backdrop-filter: blur(10px);

}
.kit-bg-blr-5 {

}

/*.ant-drawer-wrapper-body { background-color: var(--standard-bg-cold)!important; -webkit-backdrop-filter: blur(5px);}*/

.ant-drawer, .ant-drawer-top, .ant-drawer-open, .kit-bg-blr-5, .ant-drawer, .ant-drawer-right, .ant-drawer-open, .ant-drawer-top, .ant-drawer-content, .ant-drawer-mask .ant-drawer-content-wrapper {background: rgba(255, 255, 255, 0)}
.ant-drawer-content { background: var(--standard-bg-cold) !important; -webkit-backdrop-filter: blur(15px);}


/* .ant-tabs-nav-wrap{ flex: none!important; justify-content: center !important; flex: unset !important;}*/
/*.ant-tabs>.ant-tabs-nav, .ant-tabs>div>.ant-tabs-nav { justify-content: center}*/


/*.ant-tabs>.ant-tabs-nav, .ant-tabs>div>.ant-tabs-nav {*/
/*    flex: unset !important;*/
/*}*/

.stop-scrolling {
    overflow: hidden;
}


.p-format {
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

.a-flow {
    font-size: clamp(14px, 1.5vw, 22px);
}

.Container {
    margin: 0 2%;
    max-width: 100%;
    background-color: var(--standard-bg);
}



.container-main {
    width:100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}


.d2 {
    position: relative;
    width: 100%;
    min-height: 1px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    min-width: 40%;
    max-width: 55%;
}

.no-padding {
    padding: 0.5em 0 !important;
    margin: 0 0 !important;

}

.div {
    background-color: #282c34
}

.navbar {
    /*background: var(--standard-bg-op);*/
}


.container-fluid {
    background: #282c34
}

.b-example-divider {
    height: 3rem;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.ul-left {
    padding-inline-start: 0;
}

/*Transitions*/
.hero.appear {
    opacity: 0;
    filter: blur(1px);
    transform: scale(1.2);
}

.hero.appear-active {
    opacity: 1;
    filter: none;
    transform: none;
    transition: opacity 1s cubic-bezier(0.68, -0.6, 0.32, 1.4),
    filter 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.4),
    transform 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.4);
}

.hero-appear-done {
    opacity: 1;
    scroll-behavior: auto;
    transform: none;
    filter: none;
}

.a.nav-link.appear {
    opacity: 0;
}

.nav-link.appear {
    color: floralwhite;
    opacity: 0;
}

.nav-link.appear-active {
    opacity: 1;
    transition: opacity 2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.a.nav-link.appear-active {
    opacity: 1;
    transition: opacity 2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.navbar-nav.me-auto.mb-2.mb-lg-0.appear {
    opacity: 0;
}

.navbar-nav.me-auto.mb-2.mb-lg-0.appear-active {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.navbar-nav.me-auto.mb-2.mb-md-0.appear {
    opacity: 0;
}

.navbar-nav.me-auto.mb-2.mb-md-0.appear-active {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.pb-2.border-bottom.appear {
    opacity: 0;
}

.pb-2.border-bottom.appear.appear-active {
    opacity: 1;
    transition: opacity 5s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.px-4.py-5.my-5.text-center.appear {
    opacity: 0;
}

.px-4.py-5.my-5.text-center.appear-active {
    opacity: 1;
    transition: opacity 3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.col.d-flex.align-items-start.appear {
    opacity: 0;
    margin-top: -100px;
}

.col.d-flex.align-items-start.appear-active {
    margin-top: 0;
    opacity: 1;
    transition: opacity 2s cubic-bezier(0.5, -0.2, 0.32, 1), margin-top 2s cubic-bezier(0.68, -0.1, 0.32, 2.0);
}

.navbar.navbar-expand-md.navbar-dark.fixed-top.bg-dark.appear {
    opacity: 0;
    margin-top: -10px;
}

.navbar.navbar-expand-md.navbar-dark.fixed-top.bg-dark.appear-active {
    margin-top: 0;
    opacity: 1;
    transition: opacity 2s cubic-bezier(0.5, -0.2, 0.32, 1), margin-top 0.5s cubic-bezier(0.5, -0.2, 0.32, 1);
}

.about-me-transition-wrapper {
    opacity: 0;
}

.fade-enter {
    filter: blur(4px);
    opacity: 0.5;
}

.fade-active-enter {
    opacity: 1;
    filter: blur(0);
    transition: all 1s ease-in;
}

.fade-done-enter {
    opacity: 1;
    scroll-behavior: smooth;
    filter: none;
}


/*******************************
ABOUT ME TRANSITION
********************************/
.transition-wrapper {
    opacity: 0;
    transform: scale(1.2);
    filter: blur(5px);
}

.transition-wrapper-w {
    opacity: 0;
    transform: scale(1.01);
    filter: blur(2px);
}

.transition-wrapper-e {
    opacity: 0;
    transform: scale(1.01);
    filter: blur(2px);
}

.intersect {
    margin-top: 5em;
    opacity: 0;
    transform: scale(1.05);
    filter: blur(3px);
}


@media (prefers-reduced-motion: no-preference) {
    .transition-wrapper .transition-wrapper-w .transition-wrapper-e {
        transition: opacity 0.5s ease, transform 0.5s ease, filter 0.5s ease;
    }

    .about-me-wrapper {
        transition: opacity 0.5s ease, transform 0.5s ease, filter 0.5s ease;
    }

    .work-wrapper {
        transition: opacity 1s ease, transform 1s ease, filter 1s ease;
    }

    .experience-wrapper {
        transition: opacity 1s ease, transform 1s ease, filter 1s ease;
    }

    .shown {
        transition: opacity 1s ease, transform 1s ease, filter 1s ease, margin-top 0.5s ease;
    }
}

.shown {
    margin-top: -5em;
    opacity: 1;
    transform: none;
    filter: none;
}

.about-me-wrapper {
    opacity: 1;
    transform: none;
    filter: none;
}

.work-wrapper {
    opacity: 1;
    transform: none;
    filter: none;
}

.experience-wrapper {
    opacity: 1;
    transform: none;
    filter: none;
}


.wrapper-card {
    background-color: rgba(5, 23, 65, 0.46) !important;
    transition: all 1s;
}

.wrapper-card:hover {
    background-color: rgba(11, 28, 77, 0.32) !important;
    transition: all 1s;
}


.image-holder-wrapper {
    opacity: 1;
    filter: grayscale(50%);
    transition: all 0.5s;
}

.image-holder-wrapper-hov {
    opacity: 1;
    filter: none;
    transition: all 0.5s;
}


.boxy-Shadow {
    box-shadow: 0 3px 7px rgba(19, 25, 40, 0.73) !important;
    transform: scale(1.08);
    transition: transform 0.5s !important;
}
