
.heading-ame::after {
    content: "";
    display: block;
    position: relative;
    top: -15px;
    width: 300px;
    height: 1px;
    margin-left: 175px;
    background-color: #343a40;
}

.am-p1 {
    flex-basis: 50%;
}

.site-card-border-less-wrapper {
    /*padding: 30px;*/
    /*background: #ececec;*/
}


